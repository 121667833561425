/**
 * Renders the Protecting component.
 * @param {Object} props - The component props.
 * @param {Object} props.section - The section data.
 * @param {string} props.locale - The locale string.
 * @param {Object} props.shopCommon - The shop common data.
 * @param {Array} props.relatedProducts - The related products data.
 * @returns {JSX.Element} The Protecting component.
 */
import Slide from '../Slide'
import cn from 'classnames'
import s from '../../Home.module.css'
import { Link, Picture } from '@components/ui'
import Arrow from '../Arrow'
import { refLink } from '../../utils'

const Protecting = ({ section, locale, shopCommon, relatedProducts }) => {
  return (
    <>
      <Slide
        section={section}
        locale={locale}
        shopCommon={shopCommon}
        relatedProducts={relatedProducts}
        className="min-l:hidden"
      ></Slide>
      <div className="layer l:hidden">
        <div className="content">
          <div className="py-5 min-l:py-10 min-xl:py-[60px]">
            <h2
              className={cn(s.title, 'min-l:text-center')}
              dangerouslySetInnerHTML={{ __html: section.title }}
            ></h2>
            {section.lists && (
              <div
                className={cn(
                  'mt-6 grid grid-cols-24 grid-rows-2 gap-2 min-l:mt-8 min-xl:mt-12'
                )}
              >
                {section.lists?.map((item, index) => (
                  <div
                    key={index}
                    className={cn(s.picBox, {
                      'min-l:col-span-7': item['col-span'] === 7,
                      'min-l:col-span-10': item['col-span'] === 10,
                      'min-l:col-span-14': item['col-span'] === 14,
                      'min-l:row-span-2': item['row-span'] === 2,
                    })}
                  >
                    <div className="relative h-full overflow-hidden rounded-xl">
                      <Link
                        href={
                          item.learn_more
                            ? refLink(
                                item.learn_more,
                                section?.title,
                                'learn_' + (index + 1)
                              )
                            : undefined
                        }
                        className="flex h-full items-center gap-[2px] font-semibold leading-[1.2]"
                      >
                        <Picture
                          source={item.image}
                          className="h-full"
                          imgClassName="h-full"
                        />
                      </Link>
                      <div className="absolute bottom-0 left-0 w-full p-4 min-xl:p-6">
                        <h5
                          className="mb-2 font-semibold min-l:mb-[6px] min-xl:mb-2 min-xl:text-[20px] min-xxl:mb-3 min-xxl:text-xl"
                          dangerouslySetInnerHTML={{ __html: item.title }}
                        ></h5>
                        {item.learn_more ? (
                          <Link
                            href={refLink(
                              item.learn_more,
                              section?.title,
                              'learn_' + (index + 1)
                            )}
                            className="flex items-center gap-[2px] font-semibold leading-[1.2]"
                          >
                            <span>
                              {item.learn_more_text ||
                                section.learn_more_text ||
                                shopCommon.learn_more}
                            </span>
                            <Arrow />
                          </Link>
                        ) : (
                          <span className="flex items-center gap-[2px] font-semibold leading-[1.2]">
                            {item.learn_more_text ||
                              section.learn_more_text ||
                              shopCommon.learn_more}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  )
}

export default Protecting
